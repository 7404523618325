<template>
  <div class="grade_wrapper w">
    <div v-if="componentInfo.config.showTitle" class="grade_title">
      <div class="grade_title_name">
        <index-title-icon />
        <span>{{ componentInfo.config.title }}</span>
      </div>
       <NuxtLink to="/grade/list" >
        <div v-if="componentInfo.config.showMore" class="grade_title_more flex_align">更多<i class="arrows" /></div>
       </NuxtLink>
    </div>
    <div class="grade_main">
      <NuxtLink :to="'/grade/view?id=' + item.id || item.goodsId" v-for="(item, index) in componentInfo.data" :key="index" class="grade_item_wrapper">
        <div class="grade_item">
          <div v-if="item.coverUrl || item.cover" class="item_image_wrapper">
            <img class="item_image" :src="item.coverUrl || item.cover">
          </div>
          <div v-if="!item.cover && !item.id && !item.goodsId" class="default_image_wrapper">
          </div>
          <div class="grade_info">
            <div class="grade_name ellipsis" v-html="item.classroomName || item.goodsName"></div>
            <div class="grade_teacher" v-if="item.teacherList && item.teacherList.length > 0">
              <template v-for="(teacher, i) in item.teacherList">
                <div v-if="i<=2" :key="i" class="teacher_warp_item">
                  <img v-if="teacher.headImg" :src="teacher.headImg" :title="teacher.nickname" class="mgl3 cover_teacher">
                  <div class="teacher_name tx-ov">{{ teacher.nickname }}</div>
                </div>
              </template>
              <div v-if="item.teacherList.length > 3" class="teacher_warp_item">
                <img src="@/assets/pc/teacher_more.svg" class="mgl3 cover_teacher">
                <div class="teacher_name tx-ov">更多</div>
              </div>
            </div>
            <div class="grade_info_desc">
              <div class="learn_num"><span v-if="shopData && shopData.config.showLearnUserCount && shopData.config.showClassroomLearnUserCount">{{ item.studentCount + '人已报名' }}</span></div>
              <div class="price">
                <span v-if="componentInfo.config.isVipModal" :class="['current_price', !item.vipPrice ? 'free' : '']">{{ item.vipPrice || '免费' }}</span>
                <span v-else :class="['current_price', item.price == 0 ? 'free' : '']">{{ item.price || '免费' }}</span>
                <span v-if="item.linePrice != 0" class="old_price">{{ item.linePrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
import {useUserStore} from "@/stores/user";
const props = defineProps({
  componentInfo: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const { shopData } = useUserStore()
</script>
<style lang="scss" scoped>
.grade_wrapper {
  padding: 25px 0px;
}
.grade_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  line-height: 22px;
  color: #333;
}
.grade_title_name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  img {
    width: 24px;
    height: 24px;
  }
}
.grade_main {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.grade_item_wrapper {
  margin-bottom: 20px;
  padding: 0 10px;
  width: 50%;
}
.grade_item_wrapper:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
.grade_item {
  padding: 15px;
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: all 0.3s;
  }
}
.item_image_wrapper {
  overflow: hidden;
  height: 128px;
  width: 230px;
  border-radius: 6px;
  .item_image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.default_image_wrapper {
  height: 128px;
  width: 230px;
  overflow: hidden;
  background: #3d7fff12;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  .default_image {
    width: 26px;
    height: 26px;
  }
}
.grade_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-left: 10px;
}
.grade_name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 48px;
}
.grade_teacher {
  flex: 1;
  font-size: 12px;
  line-height: 18px;
  height: 42px;
  color: #999999;
}
.grade_info_desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  color: #999999;
  line-height: 18px;
  .border {
    width: 1px;
    padding: 0.5px;
    margin: 0px 10px;
    height: 12px;
    background: #ebeef5;
  }
}
.price {
  line-height: 18px;
  .current_price {
    font-size: 16px;
      font-weight: 550;
      color: #F61818;
    &.free{
      color: #58B85C;
    }
  }
  .current_price::before {
    content: '￥';
    font-size: 0.66em;
  }
  .free::before {
    display: none;
  }
  .old_price {
    margin-left: 5px;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    text-decoration-line: line-through;
  }
  .old_price::before {
    content: '￥';
    font-size: 1em;
  }
}
.grade_teacher {
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
  &.cu {
    cursor: pointer;
    &:hover {
      // color: #2256f6;
    }
  }
}
.teacher_warp_item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin-right: 10px;
  & + .teacher_warp_item {
    margin-right: 10px;
  }

  .teacher_name {
    margin-top: 2px;
    font-size: 12px;
    line-height: 20px;
    width: 48px;
    text-align: center;
  }

  &:hover .teacher_name {
    // color: #3d7fff;
  }
}

.cover_teacher {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;

  & + .cover_teacher {
    transition: all 0.3s;
    margin-left: 3px;
  }

  &.hover {
    transform: scale(1.5);
    // border: 1px solid #2256F6;
    // box-shadow: 0px 5px 15px rgba(54, 41, 183, 0.06);
  }
}
</style>
